import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from 'services/api';
import { useParams } from 'react-router-dom';

export default function DeleteItem({ id, onDeleted }) {
  const [open, setOpen] = React.useState(false);
  const { id: monitoringId } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await api.delete(`monitorings/${monitoringId}/diseases/${id}`);
      handleClose();
      onDeleted();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div>
      <Button variant="outlined" size="small" onClick={handleClickOpen}>
        Excluir
      </Button>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deseja excluir esse item?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta ação não pode ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}