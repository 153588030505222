import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
  OwnerCard,
  FarmInfoCard,
  ApplicationList,
  PlantingDate,
  FertilizingList,
  SeedTreatmentList,
  SelectField
} from './components';

import api from 'services/api';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const ShowFarm = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [planning, setPlanning] = useState(null);
  const [applications, setApplications] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [plantingDate, setPlantingDate] = useState(null);

  const getPlanning = useCallback(async () => {
    try {
      const response = await api.get(`plannings/${id}`);

      setPlanning(response.data);
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  const fetchApplications = useCallback(async () => {
    if(!selectedField) {
      return;
    }
    
    try {
      const response = await api.get(`plannings/${id}/applications?field_id=${selectedField}`);

      setApplications(response.data);
    } catch (err) {
      console.log(err);
    }
  }, [id, selectedField]);

  useEffect(() => {
    getPlanning();
    fetchApplications();
  }, [getPlanning, fetchApplications]);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={6}
          sm={6}
          xl={6}
          xs={12}
        >
          <OwnerCard name={planning?.farm?.owner?.name} />
        </Grid>
        <Grid
          item
          lg={6}
          sm={6}
          xl={6}
          xs={12}
        >
          <FarmInfoCard name={planning?.farm?.name} />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <SelectField
            fields={planning?.farm?.fields}
            onSelect={setSelectedField}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          <PlantingDate onSetPlantingDate={setPlantingDate} field={selectedField} />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <FertilizingList field={selectedField} />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <SeedTreatmentList field={selectedField} />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ApplicationList
            applications={applications}
            plantingDate={plantingDate}
            farm={planning?.farm}
            field={selectedField}
            onFetch={fetchApplications}
          />
        </Grid>
      </Grid> 
    </div>
  );
};

export default ShowFarm;
