import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import { WeedsToolbar, WeedsTable } from './components';
import api from 'services/api';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const MeasurementList = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [users, setCustomers] = useState([]);

  const fetchWeeds = useCallback(async () => {
    try {
      const response = await api.get('measurements')

      setCustomers(response.data);
    } catch (err) {
      enqueueSnackbar('Não foi possível carregar os dados. Verifique sua conexão com a internet!', {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, setCustomers]);

  useEffect(() => {
    fetchWeeds();
  }, [fetchWeeds]);

  return (
    <div className={classes.root}>
      <WeedsToolbar fetchList={fetchWeeds} />
      <div className={classes.content}>
        <WeedsTable users={users} onFetch={fetchWeeds} />
      </div>
    </div>
  );
};

export default MeasurementList;
