import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { format, parseISO } from 'date-fns';

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  ButtonGroup,
  Chip,
  Avatar
} from '@material-ui/core';

import DeleteButtomModal from '../DeleteButtomModal';
import EditMonitoringModal from '../EditMonitoringModal';
import SendMailModal from '../SendMailModal';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  chips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const TableList = props => {
  const { className, monitorings, onFetch, ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const formatDate = useCallback((value) => {
    if(!value) {
      return;
    }
    return format(parseISO(value), 'dd/MM/yyyy');
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Identificador</TableCell>
                  <TableCell>Fazenda</TableCell>
                  <TableCell align="center">Talhões</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Observações</TableCell>
                  <TableCell align="right">Opções</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {monitorings.length === 0 && (
                  <TableRow className={classes.tableRow}>
                    <TableCell colSpan="6" align="center">Sem monitoramentos cadastrados</TableCell>
                  </TableRow>
                )}
                {monitorings.map((item, index) => (
                  <TableRow className={classes.tableRow} key={index}>
                    <TableCell>{item?.id}</TableCell>
                    <TableCell>{item?.farm?.name}</TableCell>
                    <TableCell className={classes.chips}>
                      {item?.farm?.fields?.map((field, index) => (
                        <Chip
                          key={index}
                          avatar={<Avatar icon="home" />}
                          label={field.name}
                          clickable
                          color="primary"
                          component="a"
                          href={`/monitoring/${item.id}/fields/${field.id}`}
                        />
                      ))}
                    </TableCell>
                    <TableCell>{formatDate(item?.start_date)}</TableCell>
                    <TableCell>{item?.comments}</TableCell>
                    <TableCell align="right">
                      <ButtonGroup size="small" variant="contained">
                        <SendMailModal item={item} />
                        <EditMonitoringModal id={item.id} item={item} onUpdate={onFetch} />
                        <DeleteButtomModal id={item.id} onDelete={onFetch} />
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={monitorings.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

TableList.propTypes = {
  className: PropTypes.string,
  monitorings: PropTypes.array.isRequired
};

export default TableList;
