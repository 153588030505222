import React, { useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Form } from "@unform/web";
import { TextField } from 'unform-material-ui';
import { DialogContent, Grid } from '@material-ui/core';
import api from 'services/api';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  headerTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: theme.spacing(4),
  },
  title: {
    flex: 1,
    color: "#000",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateCustomerModal({ id, weed, onUpdate }) {
  const classes = useStyles();
  const formRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({
        unit: Yup.string().required('O campo é obrigatório.'),
        name: Yup.string().required('O campo é obrigatório.'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      await updateWeed(data);
      
      // reset();
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setLoading(false);
    formRef.current.reset();
  }, []);

  const updateWeed = useCallback(async (data) => {
    try {
      setLoading(true);
      await api.put(`measurements/${id}`, data);

      enqueueSnackbar('Atualizado com sucesso!', {
        variant: 'success',
      });
      formRef.current.setErrors({});
      handleClose();
      onUpdate();
    } catch (err) {
      enqueueSnackbar('Não foi possível carregar os dados. Verifique sua conexão com a internet!', {
        variant: 'error',
      });
      setLoading(false);
    }
  }, [id, enqueueSnackbar, setLoading, onUpdate, handleClose]);


  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Editar
      </Button>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Form initialData={weed} ref={formRef} onSubmit={handleSubmit}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.headerTitle}>
                ATUALIZAR UNIDADE DE MEDIDA
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent dividers className={classes.content}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" color="inherit" className={classes.title}>
                    Informe os Dados
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="unit"
                    fullWidth
                    variant="outlined"
                    label="Unidade"
                    placeholder="Unidade"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    fullWidth
                    variant="outlined"
                    label="Nome"
                    placeholder="Nome"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={loading}
                  >
                    Atualizar Dados
                  </Button>
                </Grid>
              </Grid>
          </DialogContent>
        </Form>
      </Dialog>
    </div>
  );
}
