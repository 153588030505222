import React from 'react';
import { ButtonGroup } from '@material-ui/core';
import DeleteApplication from './DeleteApplication';
import EditApplication from './EditApplication';

export default function ManageSection({ application, field, onFetch }) {
  return (
    <ButtonGroup
      size="small"
      variant="contained"
    >
      <EditApplication
        applicationId={application.id}
        field={field}
        onFetch={onFetch}
      />
      <DeleteApplication
        applicationId={application.id}
        onFetch={onFetch}
      />
    </ButtonGroup>
  )
}
