import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import ManageSection from './components/ManageSection';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import AddSection from './components/AddSection';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const SectionList = props => {
  const { className, ...rest } = props;
  const [list, setList] = useState([]);
  const { farmId } = useParams();

  const classes = useStyles();

  const fetchList = useCallback(async () => {
    try {
      const response = await api.get(`farms/${farmId}/fields`);

      setList(response.data);
    } catch (err) {
      console.log(err);
    }
  }, [farmId]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <AddSection onFetch={fetchList} />
        }
        title="Todos os talhões"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Número</TableCell>
                  <TableCell align="right">Opções</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(item => (
                  <TableRow
                    hover
                    key={item.id}
                  >
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">
                      <ManageSection section={item} onFetch={fetchList} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

SectionList.propTypes = {
  className: PropTypes.string
};

export default SectionList;
