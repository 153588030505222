import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form } from '@unform/web';
import { TextField, Select } from 'unform-material-ui';
import { Grid, MenuItem } from '@material-ui/core';
import api from 'services/api';
import { useParams } from 'react-router-dom';

export default function AddItem({ onCreated }) {
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const { id, fieldId } = useParams();

  const fetchList = useCallback(async () => {
    try {
      const response = await api.get('diseases');

      setList(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (data) => {
    try {
      await api.post(`monitorings/${id}/diseases`, {
          monitoring_id: id,
          field_id: fieldId,
          ...data
      });
      handleClose();
      onCreated();
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Adicionar
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <DialogTitle id="alert-dialog-title">Adicionar item</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Select fullWidth style={{ width: '100%' }} variant="outlined" label="Doença" name="disease_id" required>
                  {list.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <TextField fullWidth variant="outlined" label="Posição" name="position" required />
              </Grid>
              <Grid item xs={4}>
                <TextField type="number" fullWidth variant="outlined" label="% da infecção" name="percentage_of_infection" required />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" autoFocus>
              Salvar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}
