import React from 'react';
import {
  // Button,
  ButtonGroup
} from '@material-ui/core';
import DeleteSection from './DeleteSection';
// import { useParams } from 'react-router-dom';

export default function ManageSection({ section, onFetch }) {
  // const { farmId } = useParams();

  return (
    <ButtonGroup size="small" variant="contained">
      {/* <Button href={`/farms/${farmId}/fields/${section.id}`}>Detalhes</Button> */}
      <DeleteSection sectionId={section.id} onFetch={onFetch} />
    </ButtonGroup>
  )
}
