import React, { useState, useEffect } from 'react';
import { Grid, TextField, MenuItem, Card, CardContent, CardHeader, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import api from 'services/api';

const sizes = [
  {
    value: 'Ovo',
    label: 'Ovo',
  },
  {
    value: 'Ninfa',
    label: 'Ninfa',
  },
  {
    value: 'Adulto',
    label: 'Adulto',
  },
  {
    value: 'Mariposa',
    label: 'Mariposa',
  },
];

function ManagePest({ onAddItem }) {
  const [list, setList] = useState([]);
  const [pest, setPest] = useState(null);
  const [pests, setPests] = useState([]);
  const [size, setSize] = useState('');
  const [quantity, setQuantity] = useState('');

  const handleChangeSize = (event) => {
    setSize(event.target.value);
  };

  const handleChangeQuantity = (event) => {
    setQuantity(event.target.value);
  };

  const fetchList = async () => {
    try {
      const response = await api.get('pests');

      setList(response.data);
    } catch (err) {
      console.log(err);
    }
  }

  const onSubmit = () => {
    setPests([{
      pest,
      size,
      quantity
    },...pests]);

    onAddItem({
      pest_id: pest.id,
      size,
      quantity
    });
  }

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <Card>
      <CardHeader title="Pragas" />
      <CardContent>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={3}>
            <Autocomplete
              options={list}
              getOptionLabel={(option) => String(option.name)}
              value={pest}
              onChange={(event, newValue) => {
                setPest(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Pragas" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              select
              label="Tamanho"
              variant="outlined"
              fullWidth
              onChange={handleChangeSize}
              value={size}
            >
              {sizes.map((option) => (  
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="number"
              label="Quantidade"
              variant="outlined"
              fullWidth
              onChange={handleChangeQuantity}
              value={quantity}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              onClick={onSubmit}
            >
              Adicionar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Pragas</TableCell>
                  <TableCell>Tamanho</TableCell>
                  <TableCell>Quantidade</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pests.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.pest.name}</TableCell>
                    <TableCell>{item.size}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ManagePest;