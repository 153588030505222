import React, { useState, useEffect } from 'react';
import { Grid, TextField, Card, CardContent, CardHeader, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import api from 'services/api';

// import { Container } from './styles';

function ManageDiseases({ onAddItem }) {
  const [list, setList] = useState([]);
  const [disease, setDisease] = useState(null);
  const [diseases, setDiseases] = useState([]);
  const [position, setPosition] = useState('');
  const [percentageOfInfection, setPercentageOfInfection] = useState('');

  const handleChangePosition = (event) => {
    setPosition(event.target.value);
  };

  const handleChangePercentageOfInfection = (event) => {
    setPercentageOfInfection(event.target.value);
  };

  const fetchList = async () => {
    try {
      const response = await api.get('diseases');

      setList(response.data);
    } catch (err) {
      console.log(err);
    }
  }

  const onSubmit = () => {
    setDiseases([{
      disease: disease.name,
      position,
      percentageOfInfection
    },...diseases]);

    onAddItem({
      disease_id: disease.id,
      position,
      percentageOfInfection
    });
  }

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <Card>
      <CardHeader title="Doenças" />
      <CardContent>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={3}>
            <Autocomplete
              options={list}
              getOptionLabel={(option) => String(option.name)}
              value={disease}
              onChange={(event, newValue) => {
                setDisease(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Doenças" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Posição"
              variant="outlined"
              fullWidth
              onChange={handleChangePosition}
              value={position}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="% da infecção"
              variant="outlined"
              fullWidth
              onChange={handleChangePercentageOfInfection}
              value={percentageOfInfection}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              onClick={onSubmit}
            >
              Adicionar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Doenças</TableCell>
                  <TableCell>Posição</TableCell>
                  <TableCell>% da infecção</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {diseases.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.diseases}</TableCell>
                    <TableCell>{item.position}</TableCell>
                    <TableCell>{item.percentageOfInfection}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ManageDiseases;