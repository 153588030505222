import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { ButtonGroup, Card, CardContent, CardHeader, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import api from 'services/api';
import { useParams } from 'react-router-dom';

import AddItem from './components/addItem';
import EditItem from './components/EditItem';
import DeleteItem from './components/DeleteItem';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.primary.dark
  },
  differenceValue: {
    color: theme.palette.primary.dark,
    marginRight: theme.spacing(1)
  }
}));

const SwitchDate = props => {
  const { className, name, ...rest } = props;
  const [list, setList] = useState([]);
  const { id, fieldId } = useParams();

  const classes = useStyles();

  const fetchList = useCallback(async () => {
    try {
      const response = await api.get(`monitorings/${id}/diseases/${fieldId}`);

      setList(response.data);
    } catch (err) {
      console.log(err);
    }
  }, [id, fieldId]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Doenças"
        action={<AddItem onCreated={fetchList} />}
      />
      <CardContent>
       <Table>
         <TableHead>
           <TableRow>
             <TableCell>#</TableCell>
             <TableCell>Planta daninha</TableCell>
             <TableCell>Tamanho</TableCell>
             <TableCell>Quantidade</TableCell>
             <TableCell align="right">Opções</TableCell>
           </TableRow>
         </TableHead>
         <TableBody>
           {list.map((item, index) => (
             <TableRow key={index}>
               <TableCell>{index + 1}</TableCell>
              <TableCell>{item.disease.name}</TableCell>
              <TableCell>{item.position}</TableCell>
              <TableCell>{item.percentage_of_infection}</TableCell>
              <TableCell align="right">
                <ButtonGroup size="small">
                  <EditItem initialData={item} onUpdated={fetchList} />
                  <DeleteItem id={item.id} onDeleted={fetchList} />
                </ButtonGroup>
              </TableCell>
            </TableRow>
           ))}
         </TableBody>
       </Table>
      </CardContent>
    </Card>
  );
};

SwitchDate.propTypes = {
  className: PropTypes.string
};

export default SwitchDate;
