import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
  OwnerCard,
  FarmInfoCard,
  SectionList
} from './components';

import api from 'services/api';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const ShowFarm = () => {
  const classes = useStyles();
  const { id, farmId } = useParams();
  const [farm, setFarm] = useState(null);

  const getFarm = useCallback(async () => {
    try {
      const response = await api.get(`owners/${id}/farms/${farmId}`);

      setFarm(response.data);
    } catch (err) {
      console.log(err);
    }
  }, [id, farmId]);

  useEffect(() => {
    getFarm();
  }, [getFarm]);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={6}
          sm={6}
          xl={6}
          xs={12}
        >
          <FarmInfoCard name={farm?.name} />
        </Grid>
        <Grid
          item
          lg={6}
          sm={6}
          xl={6}
          xs={12}
        >
          <OwnerCard name={farm?.owner?.name} />
        </Grid>
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <SectionList />
        </Grid>
      </Grid>
    </div>
  );
};

export default ShowFarm;
