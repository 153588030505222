import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from 'services/api';
import { useSnackbar } from 'notistack';

export default function SendMailModal({ item }) {
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    if(!recipients) {
      return;
    }

    try {
      await api.post('sendMonitoringMail', {
        users: recipients,
        monitoring_id: item.id
      });

      enqueueSnackbar('Enviado com sucesso!', {
        variant: 'success',
      });
      handleClose();
    } catch (err) {
      enqueueSnackbar('Não foi possível enviar as informações. Verifique sua conexão com a internet ou tente novamente mais tarde!', {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, item, recipients, handleClose]);

  const fetchUsers = useCallback(async () => {
    if(!item) {
      return;
    }

    try {
      const response = await api.get('customers');
      
      setUsers(response.data);
    } catch (err) {
      console.log(err);
    }
  }, [item]);

  useEffect(() => {
    fetchUsers();
    setRecipients([
      {
        name: item.farm.owner.name,
        email: item.farm.owner.email
      }
    ]);
  }, [fetchUsers, item]);

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        Enviar por email
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Enviar relatório por email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Verifique as informações para enviar o relatório por email.
          </DialogContentText>
          <Autocomplete
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            options={users}
            getOptionLabel={(option) => `${option.name} <${option.email}>`}
            defaultValue={[users[users.map(elm => elm.email).indexOf(item.farm.owner.email)]]}
            onChange={(event, newValue) => {
              setRecipients(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Destinatários" placeholder="Destinatários" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Fechar
          </Button>
          <Button disabled={recipients.length === 0} onClick={handleSubmit} variant="contained" color="primary" autoFocus>
            Enviar por email
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}