import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import api from 'services/api';
import { useParams } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide
    direction="up"
    ref={ref}
    {...props}
  />;
});

export default function AddApplication({ applicationId, onFetch }) {
  const [open, setOpen] = React.useState(false);
  const { id } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const daleteRequest = useCallback(async () => {
    try {
      await api.delete(`plannings/${id}/applications/${applicationId}`);

      onFetch();
      handleClose();
    } catch (err) {
      console.log(err);
      alert('Erro ao expluir os dados.');
    }
  }, [id, applicationId, handleClose, onFetch]);

  return (
    <div>
      <Button
        color="primary"
        onClick={handleClickOpen}
        size="small" 
        variant="contained"
      >
        Excluir
      </Button>
      <Dialog
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        aria-labelledby="alert-dialog-slide-title"
        fullWidth
        keepMounted
        maxWidth="sm"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="alert-dialog-slide-title">Excluir aplicação</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Deseja excluir a aplicação?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={daleteRequest}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}