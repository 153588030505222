import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { SnackbarProvider } from 'notistack';

import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import './config/reactotronConfig';

import { chartjs } from './helpers';
import theme from './theme';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';

import history from './services/history';

import { store, persistor } from './store';

import GlobalStyle from './styles/global';

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
              <Router history={history}>
                <Routes />
                <GlobalStyle />
                <ToastContainer autoClose={3000} />
              </Router>
            </SnackbarProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    );
  }
}
