import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import { Toolbar, TableList } from './components';
import api from 'services/api';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const CustomerList = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [users, setFarms] = useState([]);

  const fetchFarms = useCallback(async () => {
    try {
      const response = await api.get(`owners/${id}/farms`);

      setFarms(response.data);
    } catch (err) {
      enqueueSnackbar('Não foi possível carregar os dados. Verifique sua conexão com a internet!', {
        variant: 'error',
      });
      console.log(err);
    }
  }, [enqueueSnackbar, setFarms, id]);

  useEffect(() => {
    fetchFarms();
  }, [fetchFarms]);

  return (
    <div className={classes.root}>
      <Toolbar fetchList={fetchFarms} />
      <div className={classes.content}>
        <TableList list={users} onFetch={fetchFarms} />
      </div>
    </div>
  );
};

export default CustomerList;
