import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from 'services/api';
import { useSnackbar } from 'notistack';

export default function DeleteUserButtom({ id, isDisabled, onDelete }) {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSumit = useCallback(async () => {
    try {
      await api.delete(`customers/${id}`);
      onDelete();
      enqueueSnackbar('Cliente excluido com sucesso!', {
        variant: 'success',
      });
      handleClose();
    } catch (err) {
      enqueueSnackbar('Não foi possível excluir o cliente. Verifique sua conexão com a internet!', {
        variant: 'error',
      });
      handleClose();
    }
  }, [id, enqueueSnackbar, onDelete]);

  return (
    <div>
      <Button variant="contained" disabled={isDisabled} color="primary" onClick={handleClickOpen}>
        Excluir
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Excluir Cliente"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente excluir o cliente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSumit} color="primary" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}