import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
`;

export const ItemList = styled.div`
  position: relative;
  width: 5rem;
  height: 5rem;
  background: #ddd;
  border-radius: 4px;
  margin-right: 10px;

  label#add-item {
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    input {
      display: none;
    }
  }

  img {
    width: 5rem;
    height: 5rem;
    border-radius: 4px;
  }

  button#close-button {
    position: absolute;
    display: none;
    width: 1.4rem;
    height: 1.4rem;
    top: -10px;
    right: -5px;
    border: 1px solid #ddd;
    border-radius: 50%;
    z-index: 999;
  }

  :hover button#close-button {
    display: block;
  }
`;
