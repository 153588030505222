import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import api from 'services/api';
import { useParams } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteSection({ seed_treatment_id, onFetch }) {
  const [open, setOpen] = React.useState(false);
  const { id } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const daleteRequest = useCallback(async () => {
    try {
      await api.delete(`plannings/${id}/seedstreatments/${seed_treatment_id}`);

      onFetch();
      handleClose();
    } catch (err) {
      console.log(err);
      alert('Erro ao excluir o produto');
    }
  }, [id, seed_treatment_id, handleClose, onFetch]);

  return (
    <div>
      <Button color="primary"
        size="small"
        variant="contained" 
        onClick={handleClickOpen}
      >
        Excluir
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Excluir produto</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Deseja excluir o produto?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={daleteRequest} color="primary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}