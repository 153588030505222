import React, {
  // useRef,
  useCallback,
  useState,
  useEffect
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from 'services/api';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  headerTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: theme.spacing(4),
  },
  title: {
    flex: 1,
    color: "#000",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateModal({ monitorings, onCreate }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [farm, setFarm] = useState(null);
  const [monitoring, setMonitoring] = useState(null);
  const [farms, setFarms] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setLoading(false);
  }, []);

  const fetchFarm = useCallback(async () => {
    try {
      const response = await api.get('farms');

      setFarms(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const createRequest = useCallback(async () => {
    try {
      await api.post('plannings', {
        monitoring_id: monitoring?.id,
        farm_id: farm?.id
      });

      onCreate();
      handleClose();
    } catch (err) {
      console.log(err);
      alert('Erro ao cadastrar o monitoramento.');
    }
  }, [monitoring, farm, handleClose, onCreate]);

  useEffect(() => {
    if(monitoring) {
      setFarm(monitoring.farm);
    }
  }, [monitoring]);

  useEffect(() => {
    fetchFarm();
  }, [fetchFarm]);


  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Registrar planejamento
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} TransitionComponent={Transition}>
        <DialogTitle id="alert-dialog-title">Registrar Planejamento</DialogTitle>
        <DialogContent dividers className={classes.content}>
            <Grid container spacing={2}>
            <Grid item xs={6}>
                <Autocomplete
                  options={monitorings}
                  getOptionLabel={(option) => `ID - ${option.id} ${option.farm.name}`}
                  value={monitoring}
                  onChange={(event, newValue) => {
                    setMonitoring(newValue);
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label="Monitoramento"
                      variant="outlined"
                      InputLabelProps={{
                      shrink: true,
                    }}
                  />}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={farms}
                  getOptionLabel={(option) => option.name}
                  value={farm}
                  onChange={(event, newValue) => {
                    setFarm(newValue);
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label="Fazenda"
                      variant="outlined"
                      InputLabelProps={{
                      shrink: true,
                    }}
                  />}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button
              color="primary"
              size="large"
              disabled={loading}
              onClick={createRequest}
              autoFocus
            >
              REGISTRAR PLANEJAMENTO
            </Button>
          </DialogActions>
      </Dialog>
    </div>
  );
}
