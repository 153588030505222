import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from 'services/api';
import { useParams } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid } from '@material-ui/core';

export default function AddField({ field, onFetch }) {
  const [open, setOpen] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [application, setApplication] = useState('');
  const { id } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleChangeApplication = (event) => {
    setApplication(event.target.value);
  }

  const handleChangeQuantity = (event) => {
    setQuantity(event.target.value);
  }

  const fetchProducts = useCallback(async () => {
    try {
      const response = await api.get('products');

      setProducts(response.data);
    } catch (err) {
      console.log(err);
      alert('Erro ao carregar os produtos');
    }
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      await api.post(`plannings/${id}/fertilizings`, {
        product_id: product?.id,
        quantity,
        application,
        field_id: field
      });

      onFetch();
      handleClose();
    } catch (err) {
      console.log(err);
      alert('Erro ao cadastrar um produto');
    }
  }, [id, application, product, quantity, field, handleClose, onFetch]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return (
    <div>
      <Button disabled={!field} size="small" variant="contained" color="primary" onClick={handleClickOpen}>
        Inserir produto
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Produto</DialogTitle>
        <DialogContent>
        <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                options={products}
                getOptionLabel={(option) => String(option.name)}
                filterSelectedOptions
                value={product}
                onChange={(event, newValue) => {
                  setProduct(newValue);
                }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Produto"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                />}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Quantidade"
                variant="outlined"
                value={quantity}
                onChange={handleChangeQuantity}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={6}
                label="Aplicação"
                variant="outlined"
                value={application}
                onChange={handleChangeApplication}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={onSubmit} color="primary">
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}