import React, {
  useCallback,
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { DialogContent, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ManageWeed from './components/ManageWeed';
import ManagePest from './components/ManagePest';
import ManageDiseases from './components/ManageDisease';
import api from 'services/api';
import Photos from './components/Photos';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  headerTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: theme.spacing(4),
  },
  title: {
    flex: 1,
    color: '#000',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide
    direction="up"
    ref={ref}
    {...props}
  />;
});

export default function CreateModal({ onCreate }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [farm, setFarm] = useState(null);
  const [farms, setFarms] = useState([]);
  const [fields, setFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [weeds, setWeeds] = useState([]);
  const [diseases, setDiseases] = useState([]);
  const [pests, setPests] = useState([]);
  const [images, setImages] = useState([]);
  const [comments, setComments] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setLoading(false);
  }, []);

  const fetchFarm = useCallback(async () => {
    try {
      const response = await api.get('farms');

      setFarms(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleChangeWeed = (data) => {
    setWeeds([data, ...weeds]);
  }

  const handlChangePest = (data) => {
    setPests([data, ...pests]);
  }

  const handleChangeDisease = ({disease_id, position, percentageOfInfection: percentage_of_infection}) => {
    setDiseases([{
      disease_id, position, percentage_of_infection
    }, ...diseases]);
  }

  const handleChangeComments = (event) => {
    setComments(event.target.value);
  }

  const onSubmit = () => {
    if(!farm) {
      alert('Selecione uma fazenda para para concluir o cadastro.');
      return;
    }

    if(selectedFields.length === 0) {
      alert('Selecione pelo menos um talhão para para concluir o cadastro.');
      return;
    }

    createRequest({
      farm_id: farm.id,
      fields: fields.map(item => item.id),
      diseases: fields.reduce((fieldAcc, field) => {
        const diseaseWithField = diseases.reduce((acc, disease) => {
          const obj = {
            field_id: field.id,
            ...disease
          };

          return [...acc, obj];
        }, []);

        return [...fieldAcc, ...diseaseWithField];
      }, []),
      pests: fields.reduce((fieldAcc, field) => {
        const pestWithField = pests.reduce((acc, pest) => {
          const obj = {
            field_id: field.id,
            ...pest
          };

          return [...acc, obj];
        }, []);

        return [...fieldAcc, ...pestWithField];
      }, []),
      weeds: fields.reduce((fieldAcc, field) => {
        const weedWithField = weeds.reduce((acc, weed) => {
          const obj = {
            field_id: field.id,
            ...weed
          };

          return [...acc, obj];
        }, []);

        return [...fieldAcc, ...weedWithField];
      }, []),
    });
    
  }

  const createRequest = useCallback(async ({ farm_id, fields, diseases, pests, weeds }) => {
    
    try {
      await api.post('monitorings', {
        farm_id,
        fields,
        comments,
        diseases,
        pests,
        weeds,
        files: images
      });

      onCreate();
      handleClose();
    } catch (err) {
      console.log(err);
      alert('Erro ao cadastrar o monitoramento.');
    }
  }, [images, handleClose, onCreate, comments]);

  const handleUploadImage = useCallback(images => {
    setImages(images.map(item => item.id));
  }, []);

  useEffect(() => {
    if(farm) {
      setFields(farm.fields);
    }
  }, [farm]);

  useEffect(() => {
    fetchFarm();
  }, [fetchFarm]);


  return (
    <div>
      <Button
        color="primary"
        onClick={handleClickOpen}
        variant="contained"
      >
        Registrar monitoramento
      </Button>
      <Dialog
        TransitionComponent={Transition}
        fullScreen
        fullWidth
        onClose={handleClose}
        open={open}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              aria-label="close"
              color="inherit"
              edge="start"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              className={classes.headerTitle}
              color="inherit"
              variant="h6"
            >
              REGISTRAR MONITORAMENTO
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent
          className={classes.content}
          dividers
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <Typography
                className={classes.title}
                color="inherit"
                variant="h6"
              >
                <Autocomplete
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setFarm(newValue);
                  }}
                  options={farms}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Fazenda"
                      variant="outlined"
                    />}
                  value={farm}
                />
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Autocomplete
                filterSelectedOptions
                getOptionLabel={(option) => String(option.name)}
                multiple
                onChange={(event, newValue) => {
                  setSelectedFields(newValue);
                }}
                options={fields}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Talhões"
                    variant="outlined"
                  />}
                value={selectedFields}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <ManageWeed onAddItem={handleChangeWeed} />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <ManagePest onAddItem={handlChangePest} />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <ManageDiseases onAddItem={handleChangeDisease} />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Photos onUploadImage={handleUploadImage} />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                label="Observações"
                multiline
                onChange={handleChangeComments}
                rows={6}
                value={comments}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Button
                color="primary"
                disabled={loading}
                onClick={onSubmit}
                size="large"
                variant="contained"
              >
                  REGISTRAR MONITORAMENTO
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
