import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/CloseRounded';
import React, { useCallback, useState } from 'react';
import api from 'services/api';

import {
  Container,
  ItemList
} from './styles';

function UploadImageGalery({ images, onUploadImage }) {
  const [list, setList] = useState(images || []);

 const handleChange = useCallback(async (e) => {
    const data = new FormData();

    data.append('file', e.target.files[0]);

    const response = await api.post('files', data);

    setList([...list, response.data]);

    onUploadImage([...list, response.data]);

  }, [list, onUploadImage]);

  const handleRemoveImage = useCallback(id => {
    setList(list.filter(item => item.id !== id));
    onUploadImage(list.filter(item => item.id !== id));
  }, [list, onUploadImage]);

  return (
    <Container>
      <ItemList>
        <label id="add-item" htmlFor="input-image">
          <AddIcon color="#333" fontSize="large" />
          <input
            id="input-image"
            type="file"
            accept="image/*"
            onChange={handleChange}
          />
        </label>
      </ItemList>

      {list.map(item => (
        <ItemList key={item.name}>
          <img src={item.url} alt={item.name} />
          
          <button id="close-button" onClick={() => handleRemoveImage(item.id)}>
            <CloseIcon color="#333" fontSize="small" />
          </button>
        </ItemList>
      ))}
    </Container>
  );
}

export default UploadImageGalery;