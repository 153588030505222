import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { format, parseISO } from 'date-fns';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import AddSection from './components/AddSection';
import api from 'services/api';
import { useParams } from 'react-router-dom';
import DeleteSection from './components/DeleteSection';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const SectionList = props => {
  const { className, onSetPlantingDate, field, ...rest } = props;
  const [list, setList] = useState([]);
  const { id } = useParams();

  const classes = useStyles();

  const fetchList = useCallback(async () => {
    if(!field) {
      return;
    }

    try {
      const response = await api.get(`plannings/${id}/plantingDates?field_id=${field}`);

      if(response.data.length > 0) {
        const { plating_date } = response.data[0];

        onSetPlantingDate(plating_date);
      }

      setList(response.data);
    } catch (err) {
      console.log(err);
      alert('Erro ao carregar os dados');
    }
  }, [id, field, onSetPlantingDate]);

  const formatDate = useCallback((value) => {
    return format(parseISO(value), 'dd/MM/yyyy');
  }, []);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          list.length === 0 ? (
            <AddSection field={field} onFetch={fetchList} />
          ) : (
            <DeleteSection materialId={list[0]?.id} onFetch={fetchList} />
          )
        }
        title="Data do platio"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Material</TableCell>
                  <TableCell>População</TableCell>
                  <TableCell>Pl/m</TableCell>
                  <TableCell>Data do platio</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(item => (
                  <TableRow
                    hover
                    key={item.id}
                  >
                    <TableCell>{item?.material?.name}</TableCell>
                    <TableCell>{item?.population}</TableCell>
                    <TableCell>{item?.population_per_meters}</TableCell>
                    <TableCell>{formatDate(item?.plating_date)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

SectionList.propTypes = {
  className: PropTypes.string
};

export default SectionList;
