import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { format, formatDistance, parseISO } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR'

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid
} from '@material-ui/core';

import ManageApplication from './components/ManageApplication';
import api from 'services/api';
import AddApplication from './components/AddApplication';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const SectionList = props => {
  const { className, plantingDate, applications, farm, field, onFetch, ...rest } = props;
  const [list, setList] = useState([]);

  const classes = useStyles();

  const fetchList = useCallback(async () => {
    if(!farm) {
      return;
    }

    try {
      const response = await api.get(`farms/${farm?.id}/fields`);

      setList(response.data);
    } catch (err) {
      console.log(err);
    }
  }, [farm]);

  const formatDate = useCallback((value) => {
    if(!value) {
      return '--';
    }

    return format(parseISO(value), 'dd/MM/yyyy');
  }, []);

  const calculateDaysAfterPlanting = useCallback((value) => {

    if(!plantingDate) {
      return '--';
    }

    const days = formatDistance(parseISO(value), parseISO(plantingDate), {
      locale: ptBr
    });

    return days;
  }, [plantingDate]);

  const formatProducts = useCallback(products => {
    return products.map(item => item.name).join(', ')
  }, []);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <Grid
      container
      spacing={4}
    >
      <Grid
        item
        xs={12}
      >
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <CardHeader
            action={
              <AddApplication
                field={field}
                fields={list}
                onFetch={onFetch}
              />
            }
            title="Aplicação"
          />
          <Divider />
        </Card>
      </Grid>
      {applications.map((item, index) => (
        <Grid
          item
          key={index}
          xs={12}
        >
          <Card
            {...rest}
            className={clsx(classes.root, className)}
          >
            <CardContent className={classes.content}>
              <PerfectScrollbar>
                <div className={classes.inner}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Aplicação</TableCell>
                        <TableCell>Produto</TableCell>
                        <TableCell>Dose</TableCell>
                        <TableCell>Vazão</TableCell>
                        <TableCell>Dias após plantio</TableCell>
                        <TableCell>Data prevista</TableCell>
                        <TableCell>Data recomendada</TableCell>
                        <TableCell>Data aplicada</TableCell>
                        <TableCell align="right">Opções</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        hover
                      >
                        <TableCell>{index + 1}°</TableCell>
                        <TableCell>{formatProducts(item.products)}</TableCell>
                        <TableCell>{item.dose}</TableCell>
                        <TableCell>{item.flow_rate}</TableCell>
                        <TableCell>{calculateDaysAfterPlanting(item.applied_date)}</TableCell>
                        <TableCell>{formatDate(item.expected_date)}</TableCell>
                        <TableCell>{formatDate(item.recommended_date)}</TableCell>
                        <TableCell>{formatDate(item.applied_date)}</TableCell>
                        <TableCell align="right">
                          <ManageApplication
                            application={item}
                            field={item}
                            onFetch={onFetch}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

SectionList.propTypes = {
  className: PropTypes.string
};

export default SectionList;
