import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  UserList as UserListView,
  FarmList as FarmListView,
  ShowFarm as ShowFarmView,
  ShowField as ShowFieldView,
  Monitoring as MonitoringView,
  Planning as PlanningView,
  ShowPlanning as ShowPlanningView,
  CustomerList as CustomerListView,
  WeedList as WeedListView,
  PestList as PestListView,
  DiseaseList as DiseaseListView,
  MaterialList as MaterialListView,
  CropList as CropListView,
  ProviderList as ProviderListView,
  ProductList as ProductListView,
  MeasurementList as MeasurementListView,
  OwnerList as OwnerListView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        isPrivate
        path="/dashboard"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        isPrivate
        path="/users"
      />
      <RouteWithLayout
        component={OwnerListView}
        exact
        layout={MainLayout}
        isPrivate
        path="/owners"
      />
      <RouteWithLayout
        component={FarmListView}
        exact
        layout={MainLayout}
        isPrivate
        path="/owners/:id/farms"
      />
      <RouteWithLayout
        component={ShowFarmView}
        exact
        layout={MainLayout}
        isPrivate
        path="/owners/:id/farms/:farmId"
      />
      <RouteWithLayout
        component={ShowFieldView}
        exact
        layout={MainLayout}
        isPrivate
        path="/monitoring/:id/fields/:fieldId"
      />
      <RouteWithLayout
        component={MonitoringView}
        exact
        layout={MainLayout}
        isPrivate
        path="/monitoring"
      />
      <RouteWithLayout
        component={ShowPlanningView}
        exact
        layout={MainLayout}
        isPrivate
        path="/plannings/:id"
      />
      <RouteWithLayout
        component={PlanningView}
        exact
        layout={MainLayout}
        isPrivate
        path="/plannings"
      />
      <RouteWithLayout
        component={CustomerListView}
        exact
        layout={MainLayout}
        isPrivate
        path="/customers"
      />
      <RouteWithLayout
        component={WeedListView}
        exact
        layout={MainLayout}
        isPrivate
        path="/weeds"
      />
      <RouteWithLayout
        component={PestListView}
        exact
        layout={MainLayout}
        isPrivate
        path="/pests"
      />
      <RouteWithLayout
        component={DiseaseListView}
        exact
        layout={MainLayout}
        isPrivate
        path="/diseases"
      />
      <RouteWithLayout
        component={MaterialListView}
        exact
        layout={MainLayout}
        isPrivate
        path="/materials"
      />
      <RouteWithLayout
        component={CropListView}
        exact
        layout={MainLayout}
        isPrivate
        path="/crops"
      />
      <RouteWithLayout
        component={ProviderListView}
        exact
        layout={MainLayout}
        isPrivate
        path="/providers"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        isPrivate
        path="/products"
      />
      <RouteWithLayout
        component={MeasurementListView}
        exact
        layout={MainLayout}
        isPrivate
        path="/measurements"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        isPrivate
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
