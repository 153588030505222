import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  ButtonGroup, Button
} from '@material-ui/core';

import DeleteButtom from '../DeleteButtom';
import EditModal from '../EditModal';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const WeedsTable = props => {
  const { className, list, onFetch, ...rest } = props;

  const classes = useStyles();

  const [selectedList, setSelectedList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { list } = props;

    let selectedList;

    if (event.target.checked) {
      selectedList = list.map(item => item.id);
    } else {
      selectedList = [];
    }

    setSelectedList(selectedList);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedList.indexOf(id);
    let newSelectedList = [];

    if (selectedIndex === -1) {
      newSelectedList = newSelectedList.concat(selectedList, id);
    } else if (selectedIndex === 0) {
      newSelectedList = newSelectedList.concat(selectedList.slice(1));
    } else if (selectedIndex === selectedList.length - 1) {
      newSelectedList = newSelectedList.concat(selectedList.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedList = newSelectedList.concat(
        selectedList.slice(0, selectedIndex),
        selectedList.slice(selectedIndex + 1)
      );
    }

    setSelectedList(newSelectedList);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedList.length === list.length}
                      color="primary"
                      indeterminate={
                        selectedList.length > 0 &&
                        selectedList.length < list.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Talhões</TableCell>
                  <TableCell align="right">Opções</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.slice(0, rowsPerPage).map(item => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={item.id}
                    selected={selectedList.indexOf(item.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedList.indexOf(item.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, item.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item?.__meta__.fields_count}</TableCell>
                    <TableCell align="right">
                      <ButtonGroup
                        size="small"
                        variant="contained"
                      >
                        <Button href={`farms/${item.id}`}>Detalhes</Button>
                        <EditModal id={item.id} weed={item} onUpdate={onFetch} />
                        <DeleteButtom id={item.id} onDelete={onFetch} />
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={list.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

WeedsTable.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array.isRequired
};

export default WeedsTable;
