import React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import UploadImageGalery from 'components/UploadImageGalery';

// import { Container } from './styles';

function Photos({ onUploadImage }) {

  return (
    <Card>
      <CardHeader title="Fotos" />
      <CardContent>
        <UploadImageGalery onUploadImage={onUploadImage} />
      </CardContent>
    </Card>
  );
}

export default Photos;