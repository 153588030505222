import React, { useState } from 'react';
import { Paper, Tab, Tabs } from '@material-ui/core';

function SelectField({ fields, onSelect }) {
  const [value, selectedValue] = useState(null);

  const handleChange = (event, newValue) => {
    selectedValue(newValue);
    onSelect(newValue);
  };

  return (
    <Paper square>
      <Tabs
        value={value}
        indicatorColor="primary"
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        {fields && fields.map(item => (
          <Tab key={item.id} label={`Talhão - ${item.id}`} value={item.id} />
        ))}
      </Tabs>
    </Paper>
  );
}

export default SelectField;