import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from 'services/api';
import { useParams } from 'react-router-dom';

export default function AddField({ onFetch }) {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const { farmId } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleChange = (event) => {
    setName(event.target.value);
  }

  const createRequest = useCallback(async () => {
    if(!name) {
      alert('Erro ao cadastrar novo talhão.');
      return;
    }
    try {
      await api.post(`farms/${farmId}/fields`, {
        name
      });

      onFetch();
      handleClose();
      setName('');
    } catch (err) {
      
    }
  }, [farmId, name, handleClose, onFetch]);

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Adicionar talhão
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Adicionar talhão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Adicione um número do talhão.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Talhão"
            fullWidth
            onChange={handleChange}
            value={name}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={createRequest} color="primary">
            Adicionar talhão
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}