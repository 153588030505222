import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from 'services/api';
import { useParams } from 'react-router-dom';
import { Grid, MenuItem } from '@material-ui/core';
import { Form } from '@unform/web';
import { Select, TextField } from 'unform-material-ui';
import * as Yup from 'yup';

export default function AddField({ field, onFetch }) {
  const [open, setOpen] = React.useState(false);
  const [materials, setMaterials] = useState([]);
  const formRef = useRef(null);
  const { id } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const fetchMaterials = useCallback(async () => {
    try {
      const response = await api.get('materials');

      setMaterials(response.data);
    } catch (err) {
      console.log(err);
      alert('Erro ao carregar os produtos');
    }
  }, []);

  const createRequest = useCallback(async (data) => {
    try {
      await api.post(`plannings/${id}/plantingDates`, {
        field_id: field,
        ...data
      });

      onFetch();
      handleClose();
    } catch (err) {
      console.log(err);
      alert('Erro ao cadastrar um produto');
    }
  }, [id, field, handleClose, onFetch]);

  const onSubmit = useCallback(async (data) => {
    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        material_id: Yup.string()
          .required(),
        population: Yup.string()
          .required(),
        population_per_meters: Yup.string()
          .required(),
        plating_date: Yup.string()
          .required()
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      createRequest(data);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }, [createRequest]);

  useEffect(() => {
    fetchMaterials();
  }, [fetchMaterials]);

  return (
    <div>
      <Button disabled={!field} size="small" variant="contained" color="primary" onClick={handleClickOpen}>
        Inserir dados
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Materiais</DialogTitle>
        <Form ref={formRef} onSubmit={onSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Select
                  fullWidth
                  style={{ width: '100%' }}
                  name="material_id"
                  label="Material"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {materials.map(item => (
                    <MenuItem key={item.id} value={item.id}>{item?.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  name="population"
                  label="População"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  name="population_per_meters"
                  label="Pl/m"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="plating_date"
                  type="date"
                  label="Aplicação"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Adicionar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}