import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from 'services/api';
import { useParams } from 'react-router-dom';
import { Grid, MenuItem } from '@material-ui/core';
import { Form } from '@unform/web';
import { TextField, Select } from 'unform-material-ui';

export default function AddApplication({ field, onFetch }) {
  const [open, setOpen] = React.useState(false);
  const [products, setProducts] = useState([]);
  const { id } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const fetchProducts = useCallback(async () => {
    try {
      const response = await api.get('products');

      setProducts(response.data);
    } catch (err) {
      console.log(err);
      alert('Erro ao carregar os produtos');
    }
  }, []);

  const handleSubmit = useCallback(async data => {
    
    try {
      await api.post(`plannings/${id}/applications`, {
        field_id: field,
        ...data
      });

      onFetch();
      handleClose();
    } catch (err) {
      console.log(err);
      alert('Erro ao cadastrar um produto');
    }
  }, [id, field, handleClose, onFetch]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return (
    <div>
      <Button
        color="primary"
        disabled={!field}
        onClick={handleClickOpen}
        size="small"
        variant="contained"
      >
        Inserir produto
      </Button>
      <Dialog
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        open={open}
      >
        <Form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Produto</DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={4}
              >
                <Select
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  label="Produto"
                  multiple
                  name="products"
                  style={{ width: '100%' }}
                  variant="outlined"
                >
                  {products.map(item => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                    >{item.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  label="Dose"
                  name="dose"
                  type="number"
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={4}
              >
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  label="Vazão"
                  name="flow_rate"
                  type="number"
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={4}
              >
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  label="Data prevista"
                  name="expected_date"
                  type="date"
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={4}
              >
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  label="Data recomendada"
                  name="recommended_date"
                  type="date"
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={4}
              >
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  label="Data aplicada"
                  name="applied_date"
                  type="date"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={handleClose}
            >
            Cancelar
            </Button>
            <Button
              color="primary"
              type="submit"
            >
            Adicionar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}