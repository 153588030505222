import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
  OwnerCard,
  FarmInfoCard,
  WeedList,
  PestList,
  DiseaseList
} from './components';

import api from 'services/api';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const ShowField = () => {
  const classes = useStyles();
  const { fieldId, id } = useParams();
  const [farm, setFarm] = useState(null);

  const getMonitoring = useCallback(async () => {
    try {
      const response = await api.get(`monitorings/${id}`);

      setFarm(response.data.farm);
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  useEffect(() => {
    getMonitoring();
  }, [getMonitoring]);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={6}
          sm={6}
          xl={6}
          xs={12}
        >
          <FarmInfoCard name={`${farm?.name} - ${farm?.owner?.name}`} />
        </Grid>
        <Grid
          item
          lg={6}
          sm={6}
          xl={6}
          xs={12}
        >
          <OwnerCard farmID={farm?.id} fieldID={fieldId} />
        </Grid>
        <Grid
          item
          lg={6}
          sm={6}
          xl={6}
          xs={12}
        >
          <WeedList />
        </Grid>
        <Grid
          item
          lg={6}
          sm={6}
          xl={6}
          xs={12}
        >
          <PestList />
        </Grid>
        <Grid
          item
          lg={6}
          sm={6}
          xl={6}
          xs={12}
        >
          <DiseaseList />
        </Grid>
      </Grid>
    </div>
  );
};

export default ShowField;
