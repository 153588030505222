import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { format, parseISO } from 'date-fns';

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  ButtonGroup,
  Button
} from '@material-ui/core';

import history from 'services/history';

import DeleteButtom from '../DeleteButtom';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  chips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const TableList = props => {
  const { className, list, onFetch, ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const formatDate = useCallback((value) => {
    return format(parseISO(value), 'dd/MM/yyyy');
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Identificador</TableCell>
                  <TableCell>Fazenda</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell align="right">Opções</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.length === 0 && (
                  <TableRow className={classes.tableRow}>
                    <TableCell colSpan="5" align="center">Sem planejamentos cadastrados</TableCell>
                  </TableRow>
                )}
                {list.map((item, index) => (
                  <TableRow className={classes.tableRow} key={index}>
                    <TableCell>{item?.id}</TableCell>
                    <TableCell>{item?.farm?.name}</TableCell>
                    <TableCell>{formatDate(item?.created_at)}</TableCell>
                    <TableCell align="right">
                      <ButtonGroup variant="contained">
                        <Button onClick={() => history.push(`/plannings/${item.id}`)}>Detalhes</Button>
                        <DeleteButtom id={item.id} onDelete={onFetch} />
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={list.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

TableList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array.isRequired
};

export default TableList;
