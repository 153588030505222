import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BuildIcon from '@material-ui/icons/Build';
import LaptopIcon from '@material-ui/icons/Laptop';
import SpaIcon from '@material-ui/icons/Spa';
import BugReportIcon from '@material-ui/icons/BugReport';
import EmojiNatureIcon from '@material-ui/icons/EmojiNatureRounded';
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbolsRounded';
import EcoRoundedIcon from '@material-ui/icons/EcoRounded';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasketRounded';
import FunctionsRoundedIcon from '@material-ui/icons/FunctionsRounded';
import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Início',
      list: [
        {
          title: 'Painel de Controle',
          href: '/dashboard',
          icon: <DashboardIcon />
        }
      ]
    },
    {
      title: 'Operacional',
      list: [
        {
          title: 'Usuários',
          href: '/users',
          icon: <PeopleIcon />
        },
        {
          title: 'Monitoramento',
          href: '/monitoring',
          icon: <LaptopIcon />
        },
        {
          title: 'Planejamento',
          href: '/plannings',
          icon: <DescriptionIcon />
        }
      ]
    },
    {
      title: 'Cadastros',
      list: [
        {
          title: 'Clientes',
          href: '/customers',
          icon: <PeopleIcon />
        },
        {
          title: 'Fazendas',
          href: '/owners',
          icon: <BuildIcon />
        },
        {
          title: 'Produtos',
          href: '/products',
          icon: <ShoppingBasketIcon />
        },
        {
          title: 'Plantas Daninhas',
          href: '/weeds',
          icon: <SpaIcon />
        },
        {
          title: 'Pragas',
          href: '/pests',
          icon: <BugReportIcon />
        },
        {
          title: 'Doenças',
          href: '/diseases',
          icon: <EmojiNatureIcon />
        },
        {
          title: 'Materiais',
          href: '/materials',
          icon: <EmojiSymbolsIcon />
        },
        {
          title: 'Culturas',
          href: '/crops',
          icon: <EcoRoundedIcon />
        },
        {
          title: 'Fornecedores',
          href: '/providers',
          icon: <LocalShippingRoundedIcon />
        }
      ]
    },
    {
      title: 'Geral',
      list: [
        {
          title: 'Configurações',
          href: '/settings',
          icon: <SettingsIcon />
        },
        {
          title: 'Unidade de Medida',
          href: '/measurements',
          icon: <FunctionsRoundedIcon />
        }
      ]
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
