import React, { useRef, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Form } from "@unform/web";
import { TextField, Select } from 'unform-material-ui';
import Paper from '@material-ui/core/Paper';
import { DialogContent, Grid, MenuItem, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import api from 'services/api';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  headerTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: theme.spacing(6),
  },
  title: {
    flex: 1,
    color: "#555",

  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InviteUsersModal() {
  const classes = useStyles();
  const formRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [roles, setRoles] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [role, setRole] = React.useState('consultant');
  const [loading, setLoading] = React.useState(false);
  const [listUsers, setListUsers] = React.useState([]);

  const fetchRoles = useCallback(async () => {
    try {
      const response = await api.get('roles');

      setRoles(response.data);
    } catch (err) {
      enqueueSnackbar('Não foi possível carregar os dados. Verifique sua conexão com a internet!', {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar]);

  async function handleSubmit(data, { reset }) {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().email().required('O campo de email é obrigatório.'),
        role: Yup.string().required(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      setListUsers([...listUsers, data]);
      formRef.current.setErrors({});
      reset();
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const handleDelete = (email) => {
    const list = listUsers.filter(item => item.email !== email);
    setListUsers(list);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setListUsers([]);
  }, []);

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const roleLabel = useCallback((slug) => {
    const data = roles.find(item => item.slug === slug);

    return data.name;
  }, [roles]);

  const handleInviteUsers = useCallback(async () => {
    if(listUsers.length === 0) {
      handleClose();
      return;
    }
    try {
      setLoading(true);
      await api.post('invites', { invites: listUsers });

      enqueueSnackbar('Convites enviados com sucesso!', {
        variant: 'success',
      });
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      enqueueSnackbar('Não foi possível enviar os convites. Verifique sua conexão com a internet!', {
        variant: 'error',
      })
    }
  }, [handleClose, enqueueSnackbar, listUsers]);

  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Enviar Convites
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.headerTitle}>
              ENVIAR CONVITES
            </Typography>
            <Button disabled={loading} color="inherit" onClick={handleInviteUsers}>
              Salvar e Enviar
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent dividers className={classes.content}>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6" color="inherit" className={classes.title}>
                  *- Campos obrigatórios
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name="email"
                  fullWidth
                  variant="outlined"
                  label="Email do usuário *"
                  placeholder="joão@gmail.com"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Select
                  name="role"
                  value={role}
                  onChange={handleChange}
                  variant="outlined"
                  style={{ width: '100%' }}
                >
                  {roles.map((option) => (
                    <MenuItem key={option.slug} value={option.slug}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  fullWidth
                >
                  Adicionar
                </Button>
              </Grid>
            </Grid>
          </Form>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Cargo</TableCell>
                      <TableCell align="right">Opções</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listUsers.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{roleLabel(row.role)}</TableCell>
                        <TableCell align="right">
                          <Button color="primary" onClick={() => handleDelete(row.email)}>Exluir</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
